<template>
  <div class="contents userMgt">
    <div class="title flexB">
      <h2>제휴문의 관리</h2>
    </div>
    <div class="box one filter">
      <div class="flex">
        <p class="bold">검색조건</p>
        <select v-model="inputMode">
          <option value="" selected disabled>선택</option>
          <option value="title">제목</option>
          <option value="content">내용</option>
        </select>
        <input type="text" v-model="searchWord" @keyup.enter="search" />
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="search">검색</button>
        <button class="line" @click="reset">초기화</button>
      </div>
    </div>

    <div class="box one partnership">
      <h2 class="bold">제휴문의 리스트</h2>
      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 30%" />
          <col style="width: 45%" />
          <col style="width: 15%" />
        </colgroup>
        <tr>
          <th scope="col">번호</th>
          <th scope="col">제목</th>
          <th scope="col">내용</th>
          <th scope="col">날짜</th>
        </tr>
        <tr v-for="(data, i) in qnaList" :key="i">
          <td>{{ currentPage == 1 ? total - i : total - (currentPage - 1) * 10 - i }}</td>
          <td>
            {{ data.category }}
          </td>
          <td>
            <router-link to @click.native="handleMove(data._id)">{{ data.title }}</router-link>
          </td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD HH:mm") }}</td>
        </tr>
      </table>
      <NoData v-if="isNodata" />
      <div class="pagination" v-if="qnaList.length > 0">
        <el-pagination layout="prev, pager, next" :total="total" :page-size="10" @current-change="handleCurrentChange"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchQnAList } from "@/api/admin";
import NoData from "@/components/contents/Nodata";
import { format } from "@/mixins/format";
export default {
  name: "partnershipManage",
  mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      category: "",
      qnaList: [],
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      isNodata: false,
      isReply: "",
    };
  },
  computed: {
    ...mapState(["navbarState"]),
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "12");
  },
  mounted() {
    this.getQnaList();
  },
  methods: {
    reset() {
      this.inputMode = "";
      this.searchWord = "";
      this.category = "";
      this.isReply = "";
      this.currentPage = 1;
      this.qnaList = [];
      this.total = 0;
      this.getQnaList();
    },
    search() {
      if (this.searchWord && this.inputMode == "") {
        this.$alert("검색 조건을 선택해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }
      this.getQnaList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getQnaList();
    },
    handleMove(id) {
      this.$router.push({ name: "partnershipDetail", query: { id: id } });
    },
    getQnaList() {
      let params = {
        page: this.currentPage,
        searchWord: this.searchWord,
        inputMode: this.inputMode,
        isReply: this.isReply,
        category: "제휴문의",
      };
      fetchQnAList(params).then((res) => {
        if (res.data.status == 200) {
          this.qnaList = res.data.data;
          this.total = res.data.total;
          this.isNodata = this.total == 0 ? true : false;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },
  },
};
</script>
